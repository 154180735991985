import React, { useState, useRef } from "react";
import nojoe from "./assets/nojoecrop.png";
import { Link } from "react-scroll";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	NavbarText,
} from "reactstrap";

function MainNav(args) {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	const hero = useRef(null);
	const policies = useRef(null);
	const footer = useRef(null);

	const scrollToSection = (elementRef) => {
		window.scrollTo({
			top: elementRef.current.offsetTop,
			behavior: "smooth",
		});
	};

	return (
		<div>
			<Navbar class="navbar navbar-light" {...args}>
				<NavbarBrand className="font-header" href="/">
					<img
						src={nojoe}
						width="30"
						height="30"
						className="d-inline-block align-top"
						alt="No Joe Logo"
					/>
				</NavbarBrand>
				<NavbarToggler className="border-danger" onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className="me-auto" navbar>
						<NavItem className="pt-4">
							<Link
								className="font-main link-styling"
								to="policies"
								spy={true}
								smooth={true}
								offset={-30}
								duration={500}
							>
								Policies
							</Link>
						</NavItem>
						<NavItem className="pt-4">
							<Link
								className="font-main link-styling"
								to="twitter-feed"
								spy={true}
								smooth={true}
								offset={-30}
								duration={500}
							>
								Latest Posts
							</Link>
						</NavItem>
						<NavItem className="pt-4 pb-3">
							<Link
								className="font-main link-styling"
								to="footer-about"
								spy={true}
								smooth={true}
								offset={80}
								duration={500}
							>
								About
							</Link>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
}

export default MainNav;
