import React from "react";
import { Timeline } from "react-twitter-widgets";
import { eagerLoadTwitterLibrary } from "react-twitter-widgets";

eagerLoadTwitterLibrary();

export default function Twitterfeed() {
	return (
		// <div class="container mt-3 twitter-feed-desktop" id="twitter-feed">
		// 	<Timeline
		// 		dataSource={{
		// 			sourceType: "profile",
		// 			screenName: "NoJoeLombardo",
		// 		}}
		// 		options={{
		// 			height: "600",
		// 			noborders: true,
		// 			nofooter: true,
		// 		}}
		// 	/>
		// </div>
		<div className="container mt-3 twitter-feed-desktop" id="twitter-feed">
			<a
				class="twitter-timeline"
				data-theme="light"
				data-height="500"
				data-chrome="noborders nofooter"
				href="https://twitter.com/NoJoeLombardo?ref_src=twsrc%5Etfw"
			>
				Tweets by NoJoeLombardo
			</a>
			<script
				async
				src="https://platform.twitter.com/widgets.js"
				charset="utf-8"
			></script>
		</div>
	);
}
