import React from "react";
import Hero from "./hero";

export default function JumbotronHero() {
	return (
		<div class="p-3 mb-4 custom-gradient rounded-3">
			<div className="desktop-view">
				<div class="container-fluid py-3 desktop-view-containers">
					<h1 class="display-6 fw-bold textborder font-header text-white text-center-desktop-view">
						Tell JOE he has to GO!
					</h1>
					{/* <p class="col-md-8 fs-4 font-main">Say NO to Joe!</p> */}
				</div>
				<div class="ratio ratio-16x9 desktop-view-containers">
					<iframe
						src="https://www.youtube.com/embed/IL2id2kRG8s"
						title="YouTube video"
						allowfullscreen
					></iframe>
				</div>
				{/* <Hero className="desktop-view-containers" /> */}
			</div>
		</div>
	);
}
