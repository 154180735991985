import React from "react";
import nojoe from "./nojoecrop.png";
import "./app.scss";
import "./scss/custom.scss";
import { Element } from "react-scroll";
import MainNav from "./navbar";
import JumbotronHero from "./jumbotron";
import Policies from "./policies";
import * as bootstrap from "bootstrap";
import FooterMain from "./footer";
import Twitterfeed from "./twitterfeed";
window.bootstrap = bootstrap;

function App() {
	return (
		<div className="App">
			<MainNav />
			<Element id="example-destination" name="example-destination">
				<JumbotronHero />
			</Element>
			<Policies id="policies" />
			{/* Seen the Sticker */}
			<Twitterfeed id="twitter-feed" />
			<FooterMain id="footer-about" />
			{/* Paid For By Nobody */}
			{/* Donate page */}
		</div>
	);
}

export default App;
