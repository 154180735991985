import React from "react";
import abortion from "./assets/abortion.jpg";
import antifa from "./assets/antifa.png";
import education from "./assets/education.png";
import gun from "./assets/gun.png";
import immigration from "./assets/immigration.jpg";
import trump from "./assets/lombardotrumpcspan.png";
import joetrumplie from "./assets/lombardotrumplie.png";
import education2 from "./assets/educationkids.webp";
import terrorist from "./assets/crime.jpg";
import immigrationerase from "./assets/immigration-erase.jpg";
import abortion2 from "./assets/abortion3.webp";
import joetrump from "./assets/lombardotrumpembrace.webp";
import covid from "./assets/covid.jpg";

export default function Policies() {
	return (
		<section class="page-section portfolio" id="policies">
			<div class="container">
				{/* <!-- Policies Section Heading--> */}
				<h2
					class="page-section-heading policies-main-font text-center text-uppercase mb-0"
					id="policies"
				>
					Policies
				</h2>
				{/* <!-- Icon Divider--> */}
				<div class="divider-custom">
					<div class="divider-custom-line"></div>
					<div class="flexin mt-2">
						<i class="fa-solid fa-arrow-down arrow-sizing"></i>
					</div>
					<div class="divider-custom-line"></div>
				</div>
				{/* <!-- Policies Grid Items--> */}
				<div class="row justify-content-center">
					{/* <!-- Policies Item 1--> */}
					<div class="col-md-6 col-lg-4 mb-5">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal1"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce">
								<img
									class="img-thumbnail modalpicture reduce"
									src={abortion2}
									alt="..."
								/>
								<p className="modaltext grow">ABORTION</p>
							</div>
						</div>
					</div>
					{/* <!-- Crime Item 5--> */}
					<div class="col-md-6 col-lg-4 mb-5 mb-md-0">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal5"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce5">
								<img
									class="img-thumbnail modalpicture"
									src={terrorist}
									alt="..."
								/>
								<p className="modaltext grow">CRIME</p>
							</div>
						</div>
					</div>
					{/* <!-- Portfolio Item 2--> */}
					<div class="col-md-6 col-lg-4 mb-5">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal2"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce2">
								<img
									class="img-thumbnail modalpicture"
									src={joetrumplie}
									alt="..."
								/>
								<p className="modaltext modaltext-edit grow">"THE BIG LIE"</p>
							</div>
						</div>
					</div>
					{/* <!-- Portfolio Item 3--> */}
					<div class="col-md-6 col-lg-4 mb-5">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal3"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce3">
								<img class="img-thumbnail modalpicture" src={covid} alt="..." />
								<p className="modaltext modaltext-edit grow">COVID-19</p>
							</div>
						</div>
					</div>
					{/* <!-- Portfolio Item 4--> */}
					<div class="col-md-6 col-lg-4 mb-5 mb-lg-0">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal4"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce4">
								<img
									class="img-thumbnail modalpicture"
									src={immigrationerase}
									alt="..."
								/>
								<p className="modaltext modaltext-edit  grow">IMMIGRATION</p>
							</div>
						</div>
					</div>
					{/* <!-- Portfolio Item 6--> */}
					<div class="col-md-6 col-lg-4">
						<div
							class="portfolio-item mx-auto"
							data-bs-toggle="modal"
							data-bs-target="#portfolioModal6"
						>
							<div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
								<div class="portfolio-item-caption-content text-center text-white">
									<i class="fas fa-plus fa-3x"></i>
								</div>
							</div>
							<div class="modalarticle growreduce6">
								<img class="img-thumbnail modalpicture" src={trump} alt="..." />
								<p className="modaltext grow">TRUMP</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* // <!-- Portfolio Modals--> */}
			{/* // <!-- Abortion Modal 1--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal1"
				tabindex="-1"
				aria-labelledby="portfolioModal1"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											Abortion{" "}
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={abortion2}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Joe Lombardo is vocally anti-choice.
											<a
												className="citation"
												href="https://www.joelombardofornv.com/issues"
											>
												[1]
											</a>
											<br></br>
											<br></br>
											Joe said "YES, absolutely" when asked if he would consider
											bans on "the Plan-B pill" and other anti-choice
											legislation.
											<a
												className="citation"
												href="https://www.youtube.com/watch?v=aR6nz6deD5s"
											>
												[2]
											</a>
											<br></br>
											<br></br>
											Joe is on record saying he supports reducing the time a
											woman can choose to have an abortion in Nevada by 11
											weeks.
											<a
												className="citation"
												href="https://www.nevadacurrent.com/2022/07/28/pro-choice-advocates-fear-dystopian-future-under-lombardo/"
											>
												[3]
											</a>
											<br></br>
											<br></br>
											We must say NO to Joe trying to control a woman's choice.
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* // <!-- Crime Modal 5--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal5"
				tabindex="-1"
				aria-labelledby="portfolioModal5"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											CRIME{" "}
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={terrorist}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Joe Lombardo is our current sheriff in Las Vegas, but he
											can't even lead the police department correctly, so how
											can he run our state correctly?<br></br> <br></br>Murders
											are up 49% in 2021 and property crimes and break-ins are
											up 11%.
											<a
												href="https://thenevadaindependent.com/article/fact-check-has-nevada-become-a-crime-cesspool-on-lombardos-watch"
												className="citation"
											>
												[1]
											</a>
											<br></br>
											<br></br>
											Joe Lombardo's own annual reports claim that the number of
											Vegas police fell below the national average (even though
											Metro increased their officer count)
											<a
												href="https://host2.adimpact.com/admo/viewer/4332fa23-6fed-4aaa-93ef-d5cdecb280f0/"
												className="citation"
											>
												[2]
											</a>
											, yet the LVMPD budget is up 8.1%. The entire department
											received $715 million in 2021, but Lombardo is failing the
											department and will fail our state.
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* // <!-- Portfolio Modal 2--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal2"
				tabindex="-1"
				aria-labelledby="portfolioModal2"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											"THE BIG LIE"
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={joetrumplie}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Joe's a sheriff. His job is to uphold the law. And yet he
											has been brainwashed to believe the big lie. So much so
											that he thinks "the FBI had no business searching
											Mar-a-Lago. They shouldn't have been there."
											<a
												href="https://www.nevadacurrent.com/blog/lombardo-says-fbi-had-no-business-searching-trumps-home/"
												className="citation"
											>
												[1]
											</a>
											<br></br>
											<br></br>
											But Joe immediately tried to backpedal on this. He claims
											he doesn't know the details, but he was so quick to stick
											up for Trump. What will he flip flop on next? <br></br>
											<br></br>
											He continues to support the lie that Trump won the
											election and is running on "reforming" our election
											integrity in a state that had no voter fraud issues aside
											from Republican members.
											<a
												href="https://thenevadaindependent.com/article/trumps-fake-electors-scandal-makes-a-farce-of-nevada-gops-voter-integrity-charade"
												className="citation"
											>
												[2]
											</a>
											<br></br>
											<br></br>
											He's also in favor of removing voting by mail which worked
											flawlessly.
											<a
												href="https://www.joelombardofornv.com/election-integrity"
												className="citation"
											>
												[3]
											</a>
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* // <!-- Portfolio Modal 3--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal3"
				tabindex="-1"
				aria-labelledby="portfolioModal3"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											COVID-19
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={covid}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Joe has the same opinion on COVID like all of his Trump
											friends do: "I never got COVID. And so in my opinion, the
											mask didn't work. It was political theater to me." (he
											actually said this)
											<a
												href="https://thenevadaindependent.com/article/on-the-record-gop-governor-candidate-joe-lombardo-on-covid-elections-and-k-12"
												className="citation"
											>
												[1]
											</a>
											<br></br>
											<br></br>
											He's angry that businesses were shut down and attributes
											this only to Steve Sisolak, and claims there were
											"oppressive mandates" because he was upset about wearing a
											mask still.
											<a
												href="https://www.joelombardofornv.com/taxes-the-economy"
												className="citation"
											>
												[2]
											</a>
											<br></br>
											<br></br>
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* // <!-- Portfolio Modal 4--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal4"
				tabindex="-1"
				aria-labelledby="portfolioModal4"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											IMMIGRATION
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={immigrationerase}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Despite being hundreds of miles away from the Mexico
											border, Joe is worried about "securing our southern
											border" and banning sanctuary cities.
											<a
												className="citation"
												href="https://twitter.com/JoeLombardoNV/status/1436375190020280326"
											>
												[1]
											</a>
											&nbsp;&nbsp;&nbsp;
											<a
												className="citation"
												href="https://twitter.com/JoeLombardoNV/status/1435998699847659524"
											>
												[2]
											</a>
											<br></br>
											<br></br>
											Joe proudly boasts about how he's put over 10,000
											undocumented people and families in jail.
											<a
												className="citation"
												href="https://thenevadaindependent.com/article/republican-gov-candidate-lombardo-asserts-role-in-10000-deportations"
											>
												[3]
											</a>
											<br></br>
											<br></br>
											The Executive Director of the ACLU of Nevada called
											Lombardo's plans on immigration "an egregious violation of
											civil rights".
											<a
												className="citation"
												href="https://thenevadaindependent.com/article/republican-gov-candidate-lombardo-asserts-role-in-10000-deportations"
											>
												[4]
											</a>
											<br></br>
											<br></br>
											Joe is determined to follow the GOP playbook and make
											immigration an even more difficult process for thousands
											of families in Nevada.
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* // <!-- Portfolio Modal 6--> */}
			<div
				class="portfolio-modal modal fade"
				id="portfolioModal6"
				tabindex="-1"
				aria-labelledby="portfolioModal6"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header border-0">
							<button
								class="btn-close"
								type="button"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body text-center pb-5">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-lg-8">
										{/* <!-- Portfolio Modal - Title--> */}
										<h2 class="portfolio-modal-title font-header text-dark text-uppercase mb-0">
											TRUMP
										</h2>
										{/* <!-- Icon Divider--> */}
										<div class="divider-custom">
											<div class="divider-custom-line"></div>
											<div class="divider-custom-icon">
												<i class="fa-solid fa-arrow-down arrow-sizing-popped"></i>
											</div>
											<div class="divider-custom-line"></div>
										</div>
										{/* <!-- Portfolio Modal - Image--> */}
										<img
											class="img-thumbnail rounded mb-5"
											src={trump}
											alt="..."
										/>
										{/* <!-- Portfolio Modal - Text--> */}
										<p class="mb-4 font-main">
											Joe is a Trump endorsed candidate and will try and run
											Nevada with the same fascists principles that Trump is
											instilling across the nation.
											<a
												className="citation"
												href="https://www.politico.com/news/2022/04/27/trump-endorses-lombardo-in-nevadas-governor-race-00028473"
											>
												[1]
											</a>
											<br></br>
											<br></br>
											Joe is an election denier and thinks the FBI "had no
											business searching Mar-a-Lago."
											<a
												className="citation"
												href="https://www.nevadacurrent.com/blog/lombardo-says-fbi-had-no-business-searching-trumps-home/"
											>
												[2]
											</a>
											<br></br>
											<br></br>
											Trump called Las Vegas a "cesspool of crime" directly to
											Joe's face. But Joe is the sheriff, so who's at fault
											here?
											<a
												className="citation"
												href="https://thenevadaindependent.com/article/trump-calls-nevada-a-crime-cesspool-boosts-lombardo-and-laxalt-in-vegasefbfbc"
											>
												[3]
											</a>
											<br></br>
											<br></br>
										</p>
										<button class="btn btn-primary" data-bs-dismiss="modal">
											<i class="fas fa-xmark fa-fw"></i>
											Close Window
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
